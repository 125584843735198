@import './variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import './mixins';
@import 'settings/variables';

// Components
@import './components/placeholders';

.error-page {
    background: $white;
    padding-top: rem-calc(136);

    @include media-breakpoint-up(md) {
        padding-top: rem-calc(186);
    }

    @include media-breakpoint-up(xl) {
        padding-top: rem-calc(168);
    }
}

.error-banner {
    margin-top: rem-calc(30);

    &__title {
        @include heading($sm-font-size: 40, $sm-line-height: 1.15, $md-line-height: 1.16, $xxl-line-height: 1.16);

        margin: 0;
        position: relative;
        z-index: 2;
    }

    @include media-breakpoint-up(xl) {
        margin-top: rem-calc(121);
    }
}

.error-hero {
    background: none;
    background: url('../images/elements/wine-stains.png');
    background-position: right rem-calc(30);
    background-repeat: no-repeat;
    background-size: 56%;
    margin: 0;
    min-height: calc(100vh - 516px);

    &__msg {
        overflow: hidden;
        padding: rem-calc(121 0);

        @include media-breakpoint-up(lg) {
            padding: rem-calc(140 0);
        }

        @include media-breakpoint-up(xl) {
            padding: rem-calc(172 0);
        }
    }

    &__title {
        @include heading($sm-font-size: 40, $sm-line-height: 1.15, $md-line-height: 1.16, $xxl-line-height: 1.16);

        margin-bottom: rem-calc(24);
        max-width: rem-calc(500);

        @include media-breakpoint-up(xl) {
            max-width: rem-calc(765);
        }
    }

    &__text {
        @include description($sm-font-size: 20, $sm-line-height: 1.4, $color: $primary, $multiple-size: false);

        margin-bottom: rem-calc(24);
        max-width: rem-calc(460);
    }

    @include media-breakpoint-up(lg) {
        background-position: right 45%;
    }

    @include media-breakpoint-up(xl) {
        height: rem-calc(1240);
    }
}

.debug-error {
    background-color: adjust-color($beige, $alpha: -0.1);
    border-radius: rem-calc(6);
    margin-bottom: rem-calc(100);
    margin-top: rem-calc(-80);
    overflow: hidden;
    padding: rem-calc(15 15 23);

    &__text {
        color: $woodsmoke;
        font-size: rem-calc(18);
        line-height: rem-calc(24);
        margin-bottom: rem-calc(30);
    }

    &__code {
        background-color: #f4f5f7;
        border-radius: rem-calc(3);
        color: #172b4d;
        display: inline-block;
        font-size: rem-calc(14);
        font-weight: 600;
        line-height: rem-calc(24);
        padding: rem-calc(8 16);
    }

    &__controller {
        color: $red;
        padding: rem-calc(8 16);
    }

    @include media-breakpoint-up(xl) {
        width: 50%;
    }

    @include media-breakpoint-up(lg) {
        margin-top: rem-calc(-100);
    }

    @include media-breakpoint-up(xl) {
        margin-top: rem-calc(-150);
    }
}
